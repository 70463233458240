import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { tracking } from "shared/data";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import { pathname as livePathName } from "scmp-app/pages/live";
import type { latestContentsQuery$key } from "scmp-app/queries/__generated__/latestContentsQuery.graphql";

import { Container, LatestItems, MoreLink, StyledContentItemHomeLatest, Title } from "./styles";

type Props = {
  className?: string;
  reference: latestContentsQuery$key;
};

export const HomeLatest: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const latestData = useFragment(
    graphql`
      fragment latestContentsQuery on Query
      @argumentDefinitions(
        latestExcludeArticleTypes: { type: "[String]" }
        latestExcludeSectionIds: { type: "[String]" }
        scmpPlusPaywallTypeIds: { type: "[String]" }
      ) {
        contents(
          contentTypes: [ARTICLE, GALLERY, VIDEO]
          first: 6
          exclude: { sectionIds: $latestExcludeSectionIds }
          articleExcludeInput: {
            articleTypeIds: $latestExcludeArticleTypes
            paywallTypeIds: $scmpPlusPaywallTypeIds
          }
          articleFilterInput: { sponsorTypes: [NONE] }
          orderBy: { field: PUBLISHED_DATE, direction: DESC }
        ) {
          edges {
            node {
              entityId
              ...homeLatestContentItemContent
            }
          }
        }
      }
    `,
    reference_,
  );
  const listOfArticles = (latestData?.contents?.edges?.map(item => item.node.entityId) ?? []).join(
    ",",
  );
  const latestItems = latestData.contents.edges;

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles,
        page_type: "homepage",
        widget_name: "latest",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  if (latestItems.length === 0) return null;
  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Latest,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title pathname={livePathName}>LATEST</Title>
        <LatestItems>
          {latestItems.map(({ node }) => (
            <StyledContentItemHomeLatest
              key={node.entityId}
              onClick={(entityId, urlAlias) => {
                sendGA4Tracking({
                  action: "click",
                  category: "recirculation",
                  customized_parameters: {
                    article_id: entityId,
                    destination_url: getAbsoluteUrl(urlAlias),
                    page_type: "homepage",
                    widget_name: "latest",
                  },
                  subcategory: "widget",
                });
              }}
              reference={node}
            />
          ))}
        </LatestItems>
        <MoreLink pathname={livePathName}>MORE LATEST NEWS</MoreLink>
      </Container>
    </BaseLinkContextProvider>
  );
};
