import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import { config } from "shared/data";

import type { PlusWidgetsSampleContentType } from "scmp-app/components/plus/plus-widget";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { ContentDiscoveryWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { useScmpWidgetConfig } from "scmp-app/lib/app/hooks";
import { useRosettaUser } from "scmp-app/lib/rosetta/hooks/rosetta-user";

import {
  Container,
  Intro,
  IntroText,
  SampleContent,
  SampleContentHeadline,
  SampleContentImage,
  SampleContentItem,
  SampleContentItems,
  SampleContentType,
  StyledBaseLink,
  StyledLogoPlus,
  StyledPlusWidgetQuotes,
  Title,
  TryForFreeButtonLink,
} from "./styles";

type Props = {
  className?: string;
  sampleContent: PlusWidgetsSampleContentType;
};
export const PlusWidgetWithoutAccess: FunctionComponent<Props> = ({ className, sampleContent }) => {
  const { scmpWidgetConfigState } = useScmpWidgetConfig();
  const discoveryModuleSampleContent = sampleContent
    ? scmpWidgetConfigState.result?.discovery_module_sample_content?.[sampleContent]
    : null;
  const { isSCMPDigitalOnlySubscriber } = useRosettaUser();

  const ga4ImpressionEvent = useCallback<() => ContentDiscoveryWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "subs",
      customized_parameters: {
        call_to_action: isSCMPDigitalOnlySubscriber ? "upgrade" : "plus trial",
        trigger_point: "content_discovery",
      },
      subcategory: "entry_point",
    }),
    [isSCMPDigitalOnlySubscriber],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<ContentDiscoveryWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: {
        isSendGA4Tracking: true,
        shouldSendOnce: true,
      },
    });

  const handleTrialButtonClickEvent = () => {
    sendGA4Tracking({
      action: "click",
      category: "subs",
      customized_parameters: {
        call_to_action: isSCMPDigitalOnlySubscriber ? "upgrade" : "plus trial",
        trigger_point: "content_discovery",
      },
      subcategory: "entry_point",
    });
  };

  const handleDownloadClickEvent = () => {
    sendGA4Tracking({
      action: "click",
      category: "plus",
      customized_parameters: {
        action_type: "download",
        trigger_point: "content_discovery",
      },
      subcategory: "sample",
    });
  };

  return (
    <Container className={className} ref={captureTrackImpressionEventTargetElement}>
      <StyledLogoPlus />
      <Intro>
        <IntroText>
          SCMP Plus is a new premium news platform that gives you an{" "}
          <span>all-inclusive edge to stay ahead on China news.</span> To access our exclusive
          content you’ll need to subscribe.
        </IntroText>
        <TryForFreeButtonLink
          onClick={handleTrialButtonClickEvent}
          pathname={
            isSCMPDigitalOnlySubscriber
              ? `${config.account.scmpAccountHost}/manage/subscription?upgrade=true`
              : "https://subscribe.scmp.com/plus"
          }
        >
          {isSCMPDigitalOnlySubscriber ? "UPGRADE" : "TRY FOR FREE"}
        </TryForFreeButtonLink>
      </Intro>
      <StyledPlusWidgetQuotes />
      <SampleContent>
        <Title>Highlights from SCMP Plus</Title>
        <SampleContentItems>
          {discoveryModuleSampleContent?.map((item, index) => (
            <SampleContentItem key={index}>
              <SampleContentType>{item.type}</SampleContentType>
              {item.downloadLink ? (
                <StyledBaseLink
                  anchorProps={{ target: "_blank" }}
                  onClick={handleDownloadClickEvent}
                  pathname={item.downloadLink}
                >
                  <SampleContentHeadline>{item.headline}</SampleContentHeadline>
                  <SampleContentImage alt={item.headline} src={item.image} />
                </StyledBaseLink>
              ) : (
                <>
                  <SampleContentHeadline>{item.headline}</SampleContentHeadline>
                  <SampleContentImage alt={item.headline} src={item.image} />
                </>
              )}
            </SampleContentItem>
          ))}
        </SampleContentItems>
      </SampleContent>
    </Container>
  );
};

PlusWidgetWithoutAccess.displayName = "PlusWidgetWithoutAccess";
