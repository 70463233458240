import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { topic, tracking } from "shared/data";
import { ServiceJournalism } from "shared/data/tracking/module";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import type { thingsToDoQueue$key } from "scmp-app/queries/__generated__/thingsToDoQueue.graphql";

import {
  Container,
  StyledContentItemHomeSecondary,
  ThingsToDoItems,
  Title,
  TitleBasLink,
} from "./styles";
type Props = {
  className?: string;
  reference: thingsToDoQueue$key;
};

export const HomeThingsToDo: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment thingsToDoQueue on Query {
        thingToDoQueue: queue(filter: { name: "homepage_service_journalism_hk" }) {
          settings
          items(first: 3) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...homeSecondaryContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const defaultTitle = "THINGS TO DO: HONG KONG";
  const customText =
    (data?.thingToDoQueue?.settings as { custom_text?: string } | undefined)?.custom_text ??
    defaultTitle;
  const thingsToItems = data?.thingToDoQueue?.items?.edges;
  const isDefaultTitle = customText.toUpperCase() === defaultTitle;

  if (thingsToItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.ThingsToDo,
      }}
    >
      <Container className={className}>
        {isDefaultTitle ? (
          <TitleBasLink
            as={topic.whatToDoInHongKong.urlAlias}
            pathname={topic.whatToDoInHongKong.urlAlias}
            query={{ module: ServiceJournalism }}
          >
            <Title>{customText}</Title>
          </TitleBasLink>
        ) : (
          <Title>{customText}</Title>
        )}
        <ThingsToDoItems>
          {thingsToItems?.map(({ node }) => (
            <StyledContentItemHomeSecondary
              key={node.entityId}
              reference={node}
              withImage
              withSection
              withVideoPlayIcon
            />
          ))}
        </ThingsToDoItems>
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeThingsToDo.displayName = "HomeThingsToDo";
