import { notEmpty, scmpPlus, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { section as sectionData } from "shared/data/section";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { aiArticleListArticleQuery } from "scmp-app/queries/__generated__/aiArticleListArticleQuery.graphql";
import type { aiArticleListSponsoredArticle$key } from "scmp-app/queries/__generated__/aiArticleListSponsoredArticle.graphql";

import { type AIEngineResponse, useTracking } from "./hooks";
import {
  Container,
  Primary,
  Secondary,
  StyledContentItemHomePrimary,
  StyledContentItemHomeSecondary,
  Tertiary,
  Title,
} from "./styles";

export type Props = {
  aiEngineResponse: AIEngineResponse;
  className?: string;
  sponsoredReference?: aiArticleListSponsoredArticle$key;
  variant: AiEngineDataType.HomeRecommendedForYou | AiEngineDataType.HomeYouMayHaveMissed;
};

const title: Record<Props["variant"], string> = {
  [AiEngineDataType.HomeRecommendedForYou]: "Recommended For You",
  [AiEngineDataType.HomeYouMayHaveMissed]: "You May Have Missed",
};

const customQueryParameterModule: Record<Props["variant"], string> = {
  [AiEngineDataType.HomeRecommendedForYou]: "AI_Recommended_for_you",
  [AiEngineDataType.HomeYouMayHaveMissed]: "AI_You_may_have_missed",
};

export const AiArticleList: FunctionComponent<Props> = ({
  aiEngineResponse: { aiEngineRecommendId, entityIds },
  className,
  sponsoredReference,
  variant,
}) => {
  const sponsoredArticle = useFragment(
    graphql`
      fragment aiArticleListSponsoredArticle on Article {
        entityId
        urlAlias
        ...homeSecondaryContentItemContent
      }
    `,
    sponsoredReference ?? null,
  );

  const { contents } = useLazyLoadQuery<aiArticleListArticleQuery>(
    graphql`
      query aiArticleListArticleQuery(
        $entityIdCount: Int!
        $entityIds: [String]
        $excludeSectionIds: [String]
        $scmpPlusPaywallTypeIds: [String]
      ) {
        contents(
          articleExcludeInput: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          exclude: { sectionIds: $excludeSectionIds }
          filter: { entityIds: $entityIds }
          first: $entityIdCount
          contentTypes: ARTICLE
        ) {
          edges {
            node {
              ... on Content {
                entityId
                urlAlias
                ...homePrimaryContentItemContent
                ...homeSecondaryContentItemContent
              }
            }
          }
        }
      }
    `,
    {
      entityIdCount: entityIds.length,
      entityIds,
      excludeSectionIds: [sectionData.posties.entityUuid, sectionData.yp.entityUuid],
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
    },
    {
      fetchPolicy: "store-or-network",
    },
  );
  const articleMap = useMemo(
    () => Object.fromEntries(contents?.edges?.map(({ node }) => [node.entityId, node])),
    [contents],
  );

  const displayAIArticles =
    entityIds
      .map(entityId => articleMap[entityId])
      .filter(node => notEmpty(node))
      .slice(0, sponsoredArticle ? 5 : 6) ?? [];
  const [primaryArticle, secondaryArticle, ...tertiaryArticles] = displayAIArticles;

  const { captureTrackImpressionEventTargetElement, trackClick } = useTracking({
    aiEngineRecommendId,
    displayEntityIds: displayAIArticles.map(({ entityId }) => entityId),
    variant,
  });

  const handleClick = useCallback(
    (item: { entityId: string; urlAlias: string }) => {
      trackClick(item.entityId, getAbsoluteUrl(item.urlAlias));
    },
    [trackClick],
  );

  const sponsorArticleImpressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        page_type: "homepage",
        widget_name: "sponsor_article",
      },
      subcategory: "widget",
    }),
    [],
  );

  const { captureTrackImpressionEventTargetElement: captureSponsorArticleImpressionEvent } =
    useTrackImpressionEventByIntersection({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: sponsorArticleImpressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: `${customQueryParameterModule[variant]}_In-house`,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title>{title[variant]}</Title>
        <Primary>
          {primaryArticle && (
            <StyledContentItemHomePrimary
              onClick={() => handleClick(primaryArticle)}
              reference={primaryArticle}
              withCaption={false}
              withComment={true}
            />
          )}
        </Primary>
        <Secondary>
          {secondaryArticle && (
            <StyledContentItemHomeSecondary
              onClick={() => handleClick(secondaryArticle)}
              reference={secondaryArticle}
              withComment={true}
              withSection={true}
              withSummary={true}
            />
          )}
        </Secondary>
        <Tertiary>
          {tertiaryArticles?.length > 0 &&
            tertiaryArticles.map(node => (
              <StyledContentItemHomeSecondary
                key={node.entityId}
                onClick={() => handleClick(node)}
                reference={node}
                withComment={true}
                withSection={true}
              />
            ))}
          {sponsoredArticle && (
            <StyledContentItemHomeSecondary
              onClick={entityId => {
                sendGA4Tracking({
                  action: "click",
                  category: "recirculation",
                  customized_parameters: {
                    article_id: entityId,
                    page_type: "homepage",
                    widget_name: "sponsor_article",
                  },
                  subcategory: "widget",
                });
              }}
              ref={captureSponsorArticleImpressionEvent}
              reference={sponsoredArticle}
              withComment={true}
              withImage={true}
              withSection={true}
            />
          )}
        </Tertiary>
      </Container>
    </BaseLinkContextProvider>
  );
};

AiArticleList.displayName = "AiArticleList";
