import { article as articleData } from "shared/data/article";

import type { DateRangePair } from "scmp-app/components/plus/date-picker/types";
import type { PlusDetailedListingType } from "scmp-app/components/plus/detailed-listing-popup/types";

export const DefaultFilterRange: DateRangePair = [undefined, undefined];

export const DataMap = {
  [articleData.types.dailyPulseBriefing.entityId]: {
    title: "Daily Pulse",
    type: "daily-pulse",
  },
  [articleData.types.factSheet.entityId]: {
    title: "FactSheet",
    type: "fact-sheet",
  },
  [articleData.types.infographic.entityId]: {
    title: "Infographic",
    type: "infographic",
  },
  [articleData.types.quickDigest.entityId]: {
    title: "Quick Digest",
    type: "quick-digest",
  },
} as Record<
  string,
  {
    title: string;
    type:
      | "infographic"
      | Extract<PlusDetailedListingType, "daily-pulse" | "fact-sheet" | "quick-digest">;
  }
>;
