import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";
import usePromise from "react-promise-suspense";

import { accountAtom } from "shared/lib/account/atoms";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { fetchAiEngineData } from "scmp-app/lib/ai-engine/apis";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import type {
  AiRecommendedForYouResponse,
  AiYouMayHaveMissedResponse,
} from "scmp-app/lib/ai-engine/types";

import type { Props } from ".";

export type AIEngineResponse = {
  aiEngineRecommendId: string;
  entityIds: string[];
};

export const useFetchAiArticles = (
  targetVariant: Props["variant"],
  excludeEntityIds: string[],
): AIEngineResponse => {
  const { user } = useAtomValue(accountAtom);

  const result = usePromise(
    async (variant: Props["variant"], excludeIds?: string[]) =>
      fetchAiEngineData<typeof variant>({
        excludeIds,
        type: variant,
        userUuid: user?.uuid,
      }),
    [targetVariant, excludeEntityIds],
  );

  const entityIds = useMemo(() => {
    if (!result?.data) return [];

    switch (targetVariant) {
      case AiEngineDataType.HomeRecommendedForYou: {
        const { results } = result.data as AiRecommendedForYouResponse;
        return [...results].sort((a, b) => a.rank - b.rank).flatMap(data => data.id);
      }
      case AiEngineDataType.HomeYouMayHaveMissed: {
        const { results } = result.data as AiYouMayHaveMissedResponse;
        return [...results].flatMap(data => data.id);
      }
    }
  }, [targetVariant, result?.data]);

  return {
    aiEngineRecommendId: result.data.rec_id,
    entityIds,
  };
};

export const useTracking = (parameters: {
  aiEngineRecommendId: string;
  displayEntityIds: string[];
  variant: Props["variant"];
}) => {
  const { aiEngineRecommendId, displayEntityIds, variant } = parameters;
  const concatenatedEntityIds = displayEntityIds.join(",");

  const widgetName =
    variant === AiEngineDataType.HomeRecommendedForYou
      ? "recommended_for_you"
      : "you_may_have_missed";

  const ga4ImpressionEvent = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: concatenatedEntityIds,
        page_type: "homepage",
        recommendation_id: aiEngineRecommendId,
        widget_name: widgetName,
      },
      subcategory: "widget",
    }),
    [aiEngineRecommendId, concatenatedEntityIds, widgetName],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const trackClick = useCallback(
    (entityId: string, destinationUrl: string) => {
      sendGA4Tracking({
        action: "click",
        category: "recirculation",
        customized_parameters: {
          article_id: entityId,
          destination_url: destinationUrl,
          list_of_articles: concatenatedEntityIds,
          page_type: "homepage",
          recommendation_id: aiEngineRecommendId,
          widget_name: widgetName,
        },
        subcategory: "widget",
      });
    },
    [aiEngineRecommendId, concatenatedEntityIds, widgetName],
  );

  return {
    captureTrackImpressionEventTargetElement,
    trackClick,
  };
};
